import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="prose prose-emerald max-w-none">
          <p className="text-lg text-gray-500 mb-8">
            Last updated: January 16, 2024
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
            <p className="text-gray-600 mb-4">
              Welcome to Edvance. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data when you visit our website and tell you about your privacy rights.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Data We Collect</h2>
            <p className="text-gray-600 mb-4">
              We collect and process the following types of personal data:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">Account information (email, name, profile details)</li>
              <li className="mb-2">Usage data (how you interact with our services)</li>
              <li className="mb-2">Device information (IP address, browser type)</li>
              <li className="mb-2">Content you create and share using our services</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. How We Use Your Data</h2>
            <p className="text-gray-600 mb-4">
              We use your personal data for these purposes:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">To provide and maintain our service</li>
              <li className="mb-2">To notify you about changes to our service</li>
              <li className="mb-2">To provide customer support</li>
              <li className="mb-2">To gather analysis or valuable information to improve our service</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Data Security</h2>
            <p className="text-gray-600 mb-4">
              We implement appropriate security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. These measures include:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">Encryption of data in transit and at rest</li>
              <li className="mb-2">Regular security assessments</li>
              <li className="mb-2">Access controls and authentication</li>
              <li className="mb-2">Secure data storage practices</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Your Rights</h2>
            <p className="text-gray-600 mb-4">
              You have the following rights regarding your personal data:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-2">Right to access your personal data</li>
              <li className="mb-2">Right to rectification of inaccurate data</li>
              <li className="mb-2">Right to erasure of your personal data</li>
              <li className="mb-2">Right to restrict processing</li>
              <li className="mb-2">Right to data portability</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Contact Us</h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about this Privacy Policy, you can contact us:
            </p>
            <ul className="list-none text-gray-600">
              <li className="mb-2">By email: team@getedvance.com</li>
              <li className="mb-2">By visiting our website: www.getedvance.com</li>
            </ul>
          </section>
        </div>

        <footer className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-gray-500 text-sm text-center">
            © 2024 Edvance. All rights reserved.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;