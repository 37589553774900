// import React from "react";

// const Footer = () => {
//   return (
//     <footer className="border-t border-gray-100 py-8 px-4">
//       <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
//         <div className="text-sm text-gray-500 text-center sm:text-left">
//           © 2024 Edvance. All rights reserved.
//         </div>
//         <div className="flex space-x-6">
//           <a href="https://twitter.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
//             Twitter
//           </a>
//           <a href="https://instagram.com/Edvance" className="text-gray-400 hover:text-emerald-500 transition">
//             Instagram
//           </a>
//         </div>
//       </div>
//       <Link to="/privacy-policy" className="text-gray-400 hover:text-emerald-500 transition">
//   Privacy Policy
// </Link>
//     </footer>
//   );
// };


// export default Footer;


import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="border-t border-gray-100 py-8 px-4">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-500 text-center sm:text-left">
          © 2024 Edvance. All rights reserved.
        </div>
        <div className="flex space-x-6">
          <Link 
            to="/privacy-policy" 
            className="text-gray-400 hover:text-emerald-500 transition"
          >
            Privacy Policy
          </Link>
          <a 
            href="https://twitter.com/Edvance" 
            className="text-gray-400 hover:text-emerald-500 transition"
          >
            Twitter
          </a>
          <a 
            href="https://instagram.com/Edvance" 
            className="text-gray-400 hover:text-emerald-500 transition"
          >
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;